.progress-bar {
  width: 100%;
  background-color: #522214;
  position: relative;
  height: 15px;
  border-radius: 10px;
  margin-top: 8px;
}

.progress-bar-part {
  position: absolute;
  top: 0;
  height: 100%;
}

.transaction.progress-bar-part {
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
}

.pendingAuthorization.progress-bar-part {
}

.walletBalance.progress-bar-part {
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
